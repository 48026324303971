// Выпадающее меню во всю ширину с отступом
.dropdown-menu {
    width: 100%;
    margin-top: 5px !important;

    .dropdown-item {
        white-space: normal !important;
    }
}

// Белый фон для контроллов с аттрибутом readonly
.form-control[readonly] {
    background-color: #ffffff;
}

.form-floating>.form-control:not(:placeholder-shown)~label {
    width: 118%;
}

.autocomplete.form-floating {
    label {
        text-wrap: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}

// Свайпер
.swiper-slide {
    width: auto;
    display: inline-block;
}

// SWITCH
.form-switch {
    padding: 0;
    display: flex;

    label {
        text-align: left;
        display: block;
        padding: 1rem calc(var(--bs-gutter-x) * .5);
        font-size: 1rem;
        font-weight: 400;
        color: #212529;
        transition: border-color 0.15s ease-in-out, box-shadow-sm 0.15s ease-in-out;
    }

    .form-check-input {
        width: 36px !important;
        height: 20px;
        margin: 18px 14px 0 0;
    }
}
