@import "swiper/swiper";

@import "assets/scss/main";
@import "assets/scss/theme";

// SWIPER
.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function : linear;
}

.partners-slider {
    .swiper-wrapper {
        margin: 30px 0;
        align-items: center;
        .swiper-slide {
            padding: 0 25px;
        }
    }
}
// END SWIPER
