@import "fonts.css";
// Required
@import "bootstrap/scss/functions";

$container-max-widths: (
    sm: 540px,
    md: 767px,
    lg: 886px,
    xl: 886px,
    xxl: 886px
);

$box-shadow-sm: none;
$border-radius-xl: 8px;

// Красный цвет бренда
$red: #EF3124;
// Темный цвет
$customBlack: #0B1F35;
// Серый фон
$customGrayBg: #F3F4F5;
// Дефолтный фон контрола
$defaultControlBg: #E7E9EB;
// Серый бордер
$borderGrayColor: rgba(109, 121, 134, 1);


// Зеленый цвет
$customGreen: #2FC26E;
// Цвет для primary, используется чаще всего, в кнопках, контроллах формы
$primary: $red;
// Цвет при фокусе контролла
$component-active-bg: #495057;
// Компонент accordion
$accordion-button-active-color: shade-color($primary, 10%);

:root {
    --bs-font-sans-serif: 'SF Pro Display';
    --title-font: 'Styrene B UI Web';
    --icon-fill-color: #EF3124;
    --bs-body-color: #0B1F35;
    --bs-btn-color: $customBlack;
    // Белый цвет
    --black: #0B1F35;
    // Белый цвет
    --white: #ffffff;
    // Цвет кнопки
    --linkColorRed: #EF3124;
    // Цвет кнопки при наведении
    --linkHoverColorRed: #de2e21;
    // Цвет кнопки при фокусе
    --linkFocusColorRed: #cb2a1f;
    // Цвет серой кнопки
    --btnSecondaryColorGray: rgba(11, 31, 53, 0.1);
    // Цвет ссылки при наведении для серой кнопки
    --btnSecondaryHoverColorGray: rgba(0, 0, 0, 0.07);
    // Цвет ссылки при фокусе для серой кнопки
    --btnSecondaryFocusColorGray: rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
        --linkHoverColorRed: #000000;
    }
}

// BTN

// END BTN

// Required
@import "bootstrap/scss/bootstrap";
// etc

body {
    font-family: 'SF Pro Display', sans-serif, 'Roboto', sans-serif;
    color: #0B1F35;
}

h2 {
    font-family: var(--title-font);
    font-feature-settings: 'ss01';
}

.shadow-sm {
    box-shadow: none!important;
}

//BTN
.btn {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 16px;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 38px;

    --bs-btn-border-width: var(--bs-border-width);
    --bs-btn-border-radius: 4px !important;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
}

.btn-primary {
    --bs-btn-color: var(--white);
    --bs-btn-hover-color: var(--white);
    --bs-btn-active-color: var(--white);
    --bs-btn-disabled-color: var(--white);
    --bs-btn-bg: var(--linkColorRed);
    --bs-btn-border-color: var(--linkColorRed);
    --bs-btn-hover-bg: var(--linkHoverColorRed);
    --bs-btn-hover-border-color: var(--linkHoverColorRed);
    --bs-btn-active-bg: var(--linkFocusColorRed);
    --bs-btn-active-border-color: var(--linkFocusColorRed);
    --bs-btn-disabled-bg: var(--linkColorRed);
    --bs-btn-disabled-border-color: var(--linkColorRed);

    &:active:focus {
        background: var(--linkColorRed);
        border-color: var(--linkColorRed);
        box-shadow: none;
    }

    &:hover {
        background: var(--linkHoverColorRed);
        border-color: var(--linkHoverColorRed);
        box-shadow: none;
    }

    &:focus {
        background: var(--linkFocusColorRed);
        border-color: var(--linkFocusColorRed);
        box-shadow: none;
    }

    &:focus-visible {
        background: var(--linkFocusColorRed);
        border-color: var(--linkFocusColorRed);
        box-shadow: none;
    }
}

.btn-light {
    --bs-btn-color: var(--black);
    --bs-btn-hover-color: var(--black);
    --bs-btn-active-color: var(--black);
    --bs-btn-disabled-color: var(--black);
    --bs-btn-bg: var(--btnSecondaryColorGray);
    --bs-btn-hover-bg: var(--btnSecondaryHoverColorGray);
    --bs-btn-active-bg: var(--btnSecondaryFocusColorGray);
    --bs-btn-disabled-bg: var(--btnSecondaryColorGray);
    border-color: transparent;

    &:active:focus {
        background: var(--btnSecondaryFocusColorGray);
        border-color: transparent;
        box-shadow: none;
    }

    &:hover {
        background: var(--btnSecondaryHoverColorGray);
        box-shadow: none;
    }

    &:focus {
        background: var(--btnSecondaryHoverColorGray);
        box-shadow: none;
    }

    &:focus-visible {
        background: var(--btnSecondaryFocusColorGray);
        box-shadow: none;
    }
}

.btn-outline-primary {
    --bs-btn-color: var(--black);
    --bs-btn-hover-color: var(--black);
    --bs-btn-active-color: var(--black);
    --bs-btn-disabled-color: var(--black);
    --bs-btn-border-color: transparent;
    --bs-btn-bg: var(--btnSecondaryColorGray);
    --bs-btn-hover-bg: var(--btnSecondaryHoverColorGray);
    --bs-btn-active-bg: var(--btnSecondaryFocusColorGray);
    --bs-btn-disabled-bg: var(--btnSecondaryColorGray);

    &:active:focus {
        background: var(--btnSecondaryFocusColorGray);
        border-color: transparent;
        box-shadow: none;
    }

    &:hover {
        background: var(--btnSecondaryHoverColorGray);
        box-shadow: none;
    }

    &:focus {
        background: var(--btnSecondaryHoverColorGray);
        box-shadow: none;
    }

    &:focus-visible {
        background: var(--btnSecondaryFocusColorGray);
        box-shadow: none;
    }
}


a {
    color: $black;

    &:hover {
        color: var(--linkHoverColorRed);
    }
}

.btn-license-number {
    line-height: normal;
}

// END BTN

// FORM SWITCH
.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='rgba(255, 255, 255, 1)'/%3e%3c/svg%3e");
    background-image: var(--bs-form-switch-bg);
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='rgba(255, 255, 255, 1)'/%3e%3c/svg%3e");
    background-image: var(--bs-form-switch-bg);
}

.form-switch {
    .form-check-input {
        background-color: $borderGrayColor;
        border-color: $borderGrayColor;
    }

    .form-check-input:checked {
        background-color: $customGreen;
        border-color: $customGreen;
    }

    .form-check-input:focus {
        box-shadow: 0 0 0 0;
    }
}

.form-switch.form-check-reverse {
    text-align: left;
    padding-right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;

    label {
        background: transparent !important;
        width: auto !important;
    }

    .form-check-input {
        float: none;
        height: 20px !important;
        margin: 0 !important;
    }
}

// END FORM SWITCH

// DROPDOWN
.dropdown-menu {
    --bs-dropdown-border-radius: 4px;
    --bs-dropdown-item-padding-y: 12px;
    border: none;
    border-radius: var(--bs-dropdown-border-radius);
    box-shadow: 0px 0px 16px rgba(11, 31, 53, 0.05), 0px 4px 8px rgba(11, 31, 53, 0.1);

    button.dropdown-item:last-child {
        &:after {
            background-image: none;
        }
    }
}

.custom-list-group {
    li:last-child {
        .dropdown-item {
            &:after {
                background-image: none;
            }
        }
    }
}

.dropdown-item {
    cursor: pointer;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:active {
        color: var(--black);
        background: var(--btnSecondaryFocusColorGray);
        &:after {
            background-image: none;
        }
    }

    &:hover {
        z-index: 1;
        position: relative;
        background-color: $customGrayBg;
        &:after {
            background-image: none;
        }
    }

    &:focus {
        background: var(--btnSecondaryHoverColorGray);
        &:after {
            background-image: none;
        }
    }

    &:focus-visible {
        background: var(--btnSecondaryFocusColorGray);
        &:after {
            background-image: none;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-image: url('data:image/svg+xml,\
                               <svg width="504" height="2" viewBox="0 0 504 2" fill="none" xmlns="http://www.w3.org/2000/svg">\
                                <path d="M0 1H504" stroke="rgba(220, 220, 221, 1)"/>\
                                </svg>');
        background-position: 0 22px;
        margin: 10px 0 -14px 0;
    }
}

.dropdown-item.active {
    background-color: inherit;
    background-image: url('data:image/svg+xml,\
                               <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5229 20 9.99998C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 9.99998C0 15.5229 4.47715 20 10 20ZM15 7.5L8.5 14L5 10.5L6.5 9L8.5 11L13.5 6L15 7.5Z" fill="rgba(47, 194, 110, 1)"/>\
                                </svg>\
                                ');
    background-repeat: no-repeat;
    background-position: top 13px right 17px;
    color: $black;

    &:hover {
        background-color: $customGrayBg;
    }
}

// END DROPDOWN

// CHECKBOX GROUP
.checkbox-group {
    background: $white !important;
    display: flex;

    div:nth-of-type(1) {
        flex-basis: 100%;
        padding: 0 !important;
        margin-bottom: 5px;
    }

    div:nth-of-type(2) {
        padding: 0 !important;
        text-align: left !important;
    }

    div:nth-of-type(3) {
        padding: 0 !important;
        text-align: left !important;
    }

    .form-check .form-check-input {
        margin-left: 0 !important;
        margin-right: 10px;
        border-color: $borderGrayColor;
    }

    .form-check-input:checked {
        background-color: $customGreen;
        border-color: $customGreen;
    }

    .form-check-input:focus {
        box-shadow: 0 0 0 0;
    }
}

// END CHECKBOX GROUP

.auto-info, .drivers, .owner {
    background-color: $white;
    margin-bottom: 30px;
}

// Контрол ввода
.form-control {
    background: $defaultControlBg;
    border-color: $defaultControlBg;
    border-radius: $border-radius-sm;
    box-shadow: none;
    height: 72px !important;
}

.form-control[readonly] {
    background: $defaultControlBg;
    border-color: $defaultControlBg;
}

.form-control:focus {
    border-color: $defaultControlBg;
    background: $defaultControlBg;
    box-shadow: none;
}

.form-control.is-invalid {
    background-image: none !important;
}

.form-control.is-invalid:focus {
    box-shadow: none;
}

// Заблокированный контролл
.form-control:disabled {
    color: $white;
    background-color: #F3F4F5;
    border-color: #F3F4F5;
    opacity: .5;
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
    background: transparent;
}

// Плавающий лейбл
.form-floating > label {
    padding: 22px 0.75rem;
}

app-form-date-field.ng-invalid.ng-touched,
app-form-text-field.ng-invalid.ng-touched {
    .form-floating.has-validation {
        input {
            border-color: $red;
        }

        label {
            color: $red;
            opacity: 1;
        }
    }

    .app-form-field-error {
        color: $red;
    }
}


//CARD
.card {
    border-radius: $border-radius-sm;
}

//end CARD

// BREADCRUMBS
app-breadcrumb {
    display: none;
}

// end breadcrumbs

// ACCORDION
.accordion {
    border-radius: $border-radius-sm;
}

.accordion-button:not(.collapsed) {
    color: $primary;
    background-color: transparent;
    //box-shadow: $box-shadow-sm;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(220, 53, 69)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    transform: rotate(-180deg);
}

// END ACCORDION

// MODAL
// Actual modal
.modal-backdrop {
    background-color: rgba(11, 31, 53);;
}

.modal-content {
    @include border-radius($border-radius-xl);
    border-color: transparent;
}

.modal-loyalty {
    max-width: 310px;
}

.modal-header {
    border-bottom: none;
    padding: 32px 48px 20px 48px
}

.modal-body {
    //padding: 10px 48px
}

.modal-footer {
    border-top: none;
    padding: 10px 48px 32px 48px;
}

app-without-license-modal {
    .modal-title {
        font-family: var(--title-font);
        font-feature-settings: 'ss01';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: var(--black);
    }
    .modal-body {
        padding: 0 48px;
    }
    .actions {
        flex-wrap: wrap;
        margin: 0 !important;
        grid-gap: 24px!important;

        button {
            flex-basis: 100%;
        }

        button:first-child {
            order: 2;
        }
    }
}

.edit-info-modal {
    background: $white !important;
    border-radius: $border-radius-xl !important;

    .modal-body {
        padding: 10px 48px
    }

    .edit-info-modal-header {
        margin: 15px 0 25px 0 !important;
    }

    .accordion {
        --bs-accordion-border-width: 0 !important;
    }

    .accordion-item {
        border-radius: $border-radius-xl;
        border: none;

        .accordion-button {
            background: $customGrayBg;
        }

        .accordion-button:not(.collapsed) {
            color: var(--black);
            background-color: $customGrayBg;
            border: none;
            box-shadow: none;
        }

        .accordion-body {
            background-color: $customGrayBg;
        }
    }

    .accordion-item:last-of-type {
        border-bottom-right-radius: $border-radius-xl !important;
        border-bottom-left-radius: $border-radius-xl !important;

        .accordion-body {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }

    .accordion-item:first-of-type {
        border-top-left-radius: $border-radius-xl !important;
        border-top-right-radius: $border-radius-xl !important;
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: $border-radius-xl !important;
        border-top-right-radius: $border-radius-xl !important;
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-right-radius: $border-radius-xl !important;
        border-bottom-left-radius: $border-radius-xl !important;
    }

    .accordion-item .accordion-button.collapsed .accordion-body {
        border-bottom-right-radius: $border-radius-xl !important;
        border-bottom-left-radius: $border-radius-xl !important;
    }

}

// END MODAL

// ALERT
.alert-secondary {
    background: $gray-100;
}

.alert {
    padding: 13px 0;
    background: inherit;
    border: none !important;
    color: $customBlack;

    .btn-close {
        display: none;
    }
}

// END ALERT

// LICENSE COMPONENT
.license {
    margin-bottom: 20px;

    form {
        .row:nth-of-type(1) {
            div.col-12:nth-of-type(1) {
                display: none !important;
                order: 1;
            }

            div.col-12:nth-of-type(2) {
                margin-left: 0 !important;
                width: 50% !important;
                margin-right: 50% !important;
                margin-bottom: 18px !important;
                order: 2;
            }

            div.col-12:nth-of-type(3) {
                width: 50% !important;
                order: 4;
                @media (max-width: 767px) {
                    order: 3;
                    width: 100% !important;
                    margin-bottom: 10px;
                }
            }

            div.col-12:nth-of-type(4) {
                width: 50% !important;
                order: 4;
                @media (max-width: 767px) {
                    order: 4;
                    width: 100% !important;
                }
            }
        }

        .row:nth-of-type(2) {
            div.col-12:nth-of-type(1) {
                margin-left: 0 !important;
                order: 1;
                width: 100%;
                margin-top: 10px;
            }
        }

        app-form-license-field {
            margin-bottom: 30px;

            &:after {
                content: 'Формат — A 999 AA 999';
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: $customBlack;
                margin-top: 6px;
            }

            input[name='licensecontrolName'] {

            }
        }
    }
}

// END LICENSE COMPONENT
// AUTO INFO
.auto-info {
    form {
        .row {
            // Марка
            div.col-12:nth-of-type(1) {
                order: 1;
            }

            // Модель
            div.col-12:nth-of-type(2) {
                order: 2;
            }

            // Мощность
            div.col-12:nth-of-type(3) {
                order: 4;
            }

            // Год выпуска
            div.col-12:nth-of-type(4) {
                order: 4;
            }

            // Тип документа
            div.col-12:nth-of-type(5) {
                order: 5;
            }

            // Номер документа
            div.col-12:nth-of-type(6) {
                order: 6;
            }

            // Дата выдачи документа
            div.col-12:nth-of-type(7) {
                order: 7;
            }

            // VIN, Шасси, Кузов
            div.col-12:nth-of-type(8) {
                order: 8;
            }

            // Номер VIN, Шасси, Кузов
            div.col-12:nth-of-type(9) {
                order: 9;
            }

            // Прробег
            div.col-12:nth-of-type(10) {
                order: 10;
            }

            // В собсвтенности с какого года
            div.col-12:nth-of-type(11) {
                order: 11;
            }

            // Стоимость
            div.col-12:nth-of-type(12) {
                order: 12;
            }

            // Новое транспортное средство
            div.col-12:nth-of-type(13) {
                order: 13;
            }
        }
    }

    .actions {
        padding: 20px 0;

        div.col-12:nth-of-type(1) {
            width: 50% !important;
            @media (max-width: 767px) {
                width: 100% !important;
            }
        }
    }
}

// END AUTO INFO
// DRIVERS
.drivers {
    .actions {
        padding: 20px 0;

        div.col-12:nth-of-type(1) {
            width: 50% !important;
            @media (max-width: 767px) {
                width: 100% !important;
            }
        }

        div.col-12:nth-of-type(2) {
            width: 50% !important;
            @media (max-width: 767px) {
                width: 100% !important;
            }
        }
    }
}

.driver {
    // Фамилия
    div.col-12:nth-of-type(1) {
        width: 50% !important;
        order: 1;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Имя
    div.col-12:nth-of-type(2) {
        width: 50% !important;
        order: 2;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Отчество
    div.col-12:nth-of-type(3) {
        width: 50% !important;
        order: 3;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Дата рождения
    div.col-12:nth-of-type(4) {
        width: 50% !important;
        order: 4;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Серия и номер ВУ
    div.col-12:nth-of-type(5) {
        width: 50% !important;
        order: 5;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Радиобокс - Права менялись в течении года
    div.col-12:nth-of-type(8) {
        width: 50% !important;
        order: 6;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Серия и номер старых прав
    div.col-12:nth-of-type(9) {
        width: 50% !important;
        order: 7;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Фамилия старых прав
    div.col-12:nth-of-type(10) {
        width: 50% !important;
        order: 8;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Дата начала стажа / помню только год
    div.col-12:nth-of-type(6) {
        width: 50% !important;
        order: 9;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Радиобокс - помню только год
    div.col-12:nth-of-type(7) {
        width: 50% !important;
        order: 10;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Радиобокс - пол водителя
    div.col-12:nth-of-type(11) {
        width: 50% !important;
        order: 11;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Радиобокс - семейное положение
    div.col-12:nth-of-type(12) {
        width: 50% !important;
        order: 12;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }

    // Количество детей
    div.col-12:nth-of-type(13) {
        width: 50% !important;
        order: 13;
        @media (max-width: 767px) {
            width: 100% !important;
        }
    }


}

.btn-clean-driver {
    --bs-btn-color: $customBlack;
    --bs-btn-bg: #ffffff;
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-active-color: #ffffff;
    --bs-btn-line-height: 24px;
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-font-size: 16px;
    --bs-btn-border-width: 0;

    &:active:focus {
        color: $primary;
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: none;
    }

    &:hover {
        color: $primary;
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: none;
    }

    &:focus {
        color: $primary;
        background: #ffffff;
        border-color: #ffffff;
        box-shadow: none;
    }
}

// END DRIVERS

// OWNER
.owner {
    .driver-selector {
        button {
            border-radius: 25px;
            background: $customBlack;
            color: $white;
            border: none !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            &:hover, &:focus, &:active, &.active {
                background: rgba(11, 31, 53, 0.07);
                color: $customBlack;
            }

        }
    }
}

// END OWNER

// OFFERS
.offers {

    .loader-post-text {
        display: none;
    }

    .offers-list {
        .offer {
            background: $customGrayBg;
            border: none;

            .badge {
                margin-left: 40px;
            }

            .description-list {
                .description-item {
                    .description-title {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #0B1F35;
                    }
                    .description-text {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(11, 31, 53, 0.7);
                    }
                }
            }

        }
    }
}

.info-filter-block {
    background: $customGrayBg;
    border: none;

    .breadcrumb {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $customBlack;
        --bs-breadcrumb-divider: " • ";
        --bs-breadcrumb-divider-color: #E1E1E1;
    }

    .breadcrumb-item {
        color: #98A2B3;
    }

    .second-title-item {
        color: #98A2B3;
        font-size: 14px;
        max-width: 186px;
    }

    .btn-edit {
        background: none;
        border: none;
        color: black;
        height: 30px;
        line-height: 10px;

        &:hover, &:focus, &:active {
            color: $primary;
        }
    }
}

.mini-kasko {
    border-radius: 8px!important;
}

// END OFFERS

// PROGRESS
.progress {
    height: 6px;
    margin-bottom: 24px!important;
}

.progress-bar {
    background: $customGreen;

    span {
        display: none;
    }
}
// END PROGRESS

// MINI LANDING
.mini-landing.mobile .mini-landing-bg img {
    background-size: cover;
    width: calc(100% - -35px);
    margin: -20px -20px 0 -20px;
}

.mini-landing.mobile {
    .title {
        font-family: var(--common-font);
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;

        letter-spacing: 0.36px;

        color: #000000;
    }

    .post-title {
        margin-top: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        letter-spacing: 0;
        margin-bottom: 36px;
    }

    .post-title.mb-0 {
        margin-bottom: 0;
    }

    .small-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.38px;
        color: #000000;
        margin-top: 36px;
    }

    .tile-list {
        .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 24px 0;
        }

        .image {
            width: 48px;
            height: 48px;
            margin-right: 17px;
        }

        .item-content {
            .item-content-title {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                margin-top: 0;
            }

            .sub-title {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba(60, 60, 67, 0.6);
            }
        }
    }

    .promo-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(60, 60, 67, 0.6);
        margin-top: 12px;
    }

    .control {
        width: 100%;
        margin: 30px 0 80px 0;

        button {
            display: block;
            margin: 0 auto;
            padding: 16px;
            width: 100%;
            max-width: 335px;
            height: 56px;
            background: #202022;
            color: #FFFFFF;
            border-radius: 12px;
            flex-grow: 0;
            cursor: pointer;
            border: none;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.408px;
            position: fixed;
            bottom: 10px;

            &:hover {
                background: var(--linkHoverColorRed);
                color: #FFFFFF;
            }

            @media(max-width: 768px) {
                width: calc(100% - 30px);
                min-width: calc(100% - 30px);
            }
        }
    }

    .promo-info {
        padding: 20px 0 0 0;
        text-align: left;
        max-width: 680px;

        a {
            font-size: 16px;
            display: inline-block;
            line-height: 24px;
            color: var(--linkHoverColorRed);
            cursor: pointer;
            transition: .3s;

            &:hover {
                color: var(--color-basic-dark);
            }
        }

        ul {
            margin: 15px 0 15px 30px;
        }
    }
}

// END MINI LANDING

// AUTOCOMPLETE
.icon-chevron {
    top: 32px !important;
}

// END AUTOCOMPLETE

// Страница success
.success-page {
    .card {
        box-shadow: none!important;
        border: none!important;
        padding: 15px!important;

        .success-title {
            font-family: var(--title-font);
            font-feature-settings: 'ss01';
            font-weight: 500!important;
            font-size: 32px!important;
            line-height: 40px!important;
            margin-top: 24px!important;
            color: var(--black);
        }
        .success-post-title {
            font-family: var(--bs-font-sans-serif);
            font-style: normal!important;
            font-weight: 400!important;
            font-size: 16px!important;
            line-height: 24px!important;
            margin-top: 3px!important;
        }
        .success-info {
            margin-top: 0!important;
        }

        .success-info-block {
            background-color: $customGrayBg;
            text-align: left;
            padding: 18px 24px;
            margin: 48px 0 0 0!important;
            width: 100%!important;
            border-radius: $border-radius-xl;

            .success-info {
                font-family: var(--title-font)!important;
                font-style: normal!important;
                font-weight: 500!important;
                font-size: 20px!important;
                line-height: 24px!important;
                font-feature-settings: 'ss01' on;
            }

            .success-list {
                margin: 19px 0 0 0!important;
                .success-list-item {
                    font-weight: 400!important;
                    font-size: 16px!important;
                    line-height: 24px!important;
                    margin: 7px 0;
                    letter-spacing: 0.9px;
                    padding-left: 12px;
                    &::before {
                        font-family: var(--bs-font-sans-serif)!important;
                        font-style: normal!important;
                        font-weight: 400!important;
                        font-size: 16px!important;
                        line-height: 24px!important;
                        content: "—"!important;
                        color: var(--black)!important;
                        margin-left: -24px!important;
                        margin-right: 4px;
                    }
                }
            }
        }

        .success-action {
            margin-top: 20px!important;
        }
    }

    .success-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.780288 12.3158C1.65342 6.21531 6.21531 1.65342 12.3158 0.780288C15.4298 0.334589 19.3246 0 24 0C28.6754 0 32.5702 0.334589 35.6842 0.780288C41.7847 1.65342 46.3466 6.21531 47.2197 12.3158C47.6654 15.4298 48 19.3246 48 24C48 28.6754 47.6654 32.5702 47.2197 35.6842C46.3466 41.7847 41.7847 46.3466 35.6842 47.2197C32.5702 47.6654 28.6754 48 24 48C19.3246 48 15.4298 47.6654 12.3158 47.2197C6.21531 46.3466 1.65342 41.7847 0.780288 35.6842C0.334589 32.5702 0 28.6754 0 24C0 19.3246 0.334589 15.4298 0.780288 12.3158Z' fill='%23EBF9F1'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.9029 29L32 19.5187L30.5683 18L21.9188 26.1317L18.4484 22.8244L17 24.3274L21.9029 29Z' fill='%232FC26E'/%3E%3C/svg%3E%0A");
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 48px;
        svg {
            display: none;
        }
    }

    .success-list-item svg {
        fill: $customGreen !important;
    }
}

// END Страница success
